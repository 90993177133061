<template>
  <div :key="chartKey">
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
        order="1"
      >
        <statistics-card></statistics-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
        order="2"
      >
        <statistics-card></statistics-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
        order="3"
      >
        <mfr-market-share></mfr-market-share>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
        order="4"
      >
        <mfr-market-share></mfr-market-share>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="12"
        order="5"
      >
        <analytics-card-sales-by-molecule></analytics-card-sales-by-molecule>
      </v-col>
      <v-col
        cols="12"
        md="8"
        sm="12"
        order="6"
      >
        <analytics-user-table></analytics-user-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import { debounce } from 'lodash'
import mfrMarketShare from '@/components/charts/mfrMarketShare.vue'
import StatisticsCard from '@/components/charts/StatisticsCard.vue'
import AnalyticsUserTable from '@/components/tables/AnalyticsUserTable.vue'
import AnalyticsCardSalesByMolecule from '@/components/tables/AnalyticsCardSalesByMolecule.vue'
import { windowAttributes } from '@/util/functions/functionsData'
import { handleResize } from '@/util/functions'
import { chartService, apiService } from '@/api/index'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'PharmacyView',
  components: {
    mfrMarketShare,
    StatisticsCard,
    AnalyticsUserTable,
    AnalyticsCardSalesByMolecule,
  },
  setup() {
    const selectedPharmacies = ref([])
    const filterStore = useFilterStore()

    return {
      selectedPharmacies,
      filterStore,
    }
  },
  data() {
    return {
      chartKey: 0,
      molecule: [],
      cardHeight: 'auto',
      fullHeight: 'auto',
      windowAttributes,
      handleResize,
      dateRange: [],
      pharmacyListData: [],
      moleculeListData: [],
      dataTable: [],
      icons: {
        mdiMagnify,
      },
    }
  },
  created() {
    window.addEventListener('resize', this.debouncedMatchHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedMatchHeight)
    this.$root.$off('mfr-market-share-pie-chart')
    this.$root.$off('mfr-heat-map')
    this.$root.$off('refresh-charts')
    this.$root.$off('mfr-heat-map-filter-by-mfr')
    this.$root.$off('reset-mfr-heat-map-filter-by-mfr')
  },
  async mounted() {
    await this.fetchPharmacyList()
    await this.matchHeight()
  },
  methods: {
    async fetchPharmacyList() {
      this.loading = true
      const token = await this.$auth.getTokenSilently()
      const body = {
        dateRange: this.dateRange,
        accNumbers: this.selectedPharmacies,
        groupCode: this.molecule,
      }
      const pharmList = chartService.getChart(token, 'pharmacyListSelect')
      const molList = chartService.getChart(token, 'moleculeList')
      const table = apiService.getData(token, 'searchResults', body)

      const [pharmacyListData, moleculeListData, dataTable] = await Promise.all([pharmList, molList, table])
      this.pharmacyListData = pharmacyListData
      this.moleculeListData = moleculeListData
      this.dataTable = dataTable
      this.loading = false
    },
    async matchHeight() {
      this.windowAttributes = await this.handleResize()
      this.cardHeight = ((this.windowAttributes.height - 150) / 2).toString()
      this.fullHeight = (this.windowAttributes.height - 150).toString()
    },
    debouncedMatchHeight: debounce(function () {
      this.matchHeight()
    }, 500),
    async updateFilter(date) {
      const token = await this.$auth.getTokenSilently()
      this.dateRange = date
      const body = {
        dateRange: this.dateRange,
        accNumbers: this.selectedPharmacies,
        groupCode: this.molecule,
      }
      this.dataTable = await apiService.getData(token, 'searchResults', body)
      this.$root.$emit('refresh-charts')
    },
  },
}
</script>
